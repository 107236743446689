<script setup lang="ts">
import logo from '@/assets/images/logo.png'

import type { CanvasFilter } from '=/types'

import { ref, shallowRef, watch } from 'vue'
import { useCanvas } from '.'
import { api } from '@/services/api'

import Bricks from '@/container/components/filters/bricks/Bricks.vue'

import Bonds from '@/container/components/filters/Bonds.vue'
import Sizes from '@/container/components/filters/Sizes.vue'
import Joints from '@/container/components/filters/Joints.vue'

import Actions from '@/container/components/Actions.vue'

import Accordion, { type AccordionObject } from '@/components/Accordion.vue'

const imgElement = ref<HTMLCanvasElement | null>(null)

const initial = await api.get<CanvasFilter>(`/api/initial`)

const { values, rules, render, download } = await useCanvas(imgElement, initial)

watch(values.form, render, { deep: true, immediate: true })

const chooseBricks = ref<boolean>(false)

const accordions = shallowRef<AccordionObject[]>([
  {
    title: 'Verband',
    component: Bonds
  },
  {
    title: 'Afmetingen',
    component: Sizes,
    info: 'informatie over options en afmetingen'
  },
  {
    title: 'Voeg',
    component: Joints
  }
])
</script>

<template>
  <canvas ref="imgElement" class="content__img"></canvas>
  <div class="config" :class="{ more: chooseBricks }">
    <div class="config__header">
      <img :src="logo" alt="logo" class="block__img" />
    </div>
    <div class="config__main">
      <Bricks v-model:show="chooseBricks" v-model:values="values" :rules />
      <Accordion v-model:accordion="accordions" v-model:values="values" :rules />
    </div>
  </div>
  <Actions @render="render" @export="download" />
</template>
